import React from 'react'
import Container from '../components/container'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

class DocsTemplate extends React.Component {
    render() {

        return (
            < Layout location={this.props.location}>
                <Container
                    css={{
                        display: `flex`, marginLeft: `16rem`, padding: `3rem 5rem `, marginTop: `3.5rem`, flex: `1 0 80% `, flexDirection: `column`
                    }}
                >
                    <h1 style={{ paddingBottom: `1rem` }}>{this.props.data.markdownRemark.frontmatter.title}</h1>
                    <div style={{ display: `flex`, flexDirection: `column`, justifyContent: `center`, width: `80%`, margin: `5px auto` }} dangerouslySetInnerHTML={{
                        __html: this.props.data.markdownRemark.html,
                    }}>
                    </div>
                </Container>
            </ Layout>
        )
    }

}

export default DocsTemplate

export const query = graphql`
    query TemplateDocsMarkdown($slug: String!, $section: String!) {
                    markdownRemark(fields: {slug: {eq: $slug}, section: {eq: $section}}) {
                    html
              excerpt
                timeToRead
              fields {
                    slug
                section
                }
              frontmatter {
                    title
                }
                }
          }`